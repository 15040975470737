import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';
import Disclaimer from '../components/Disclaimer';

const WhatsAppEvropaenasha = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      title="Спаси бъдещето ни! Гласувай на европейските избори - ето как! 🗳"
      description="Натисни линка и изпрати съобщение на всички в WhatsApp - дай им глас! ➡️"
      shareImage="bulgarian"
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`Ти си българин който живее в Обединеното Кралство?
Имаме право на глас в Европейските избори в Обединеното Кралство!

*ВАЖНО!!!*
💥 Изпрати това съобщение на всички българи в Обединеното Кралство които познаваш - това е нашия дом също! 💥
Натисни тук: https://saveourfuture.uk/evropaenasha

*Регистрирай се за гласуване* - 3 минути
следвай стъпките тък:
https://saveourfuture.uk/simple

*Направи го до 7-ми Май (направи го сега!):*
(Ако избереш пощенски вот може да гласуваш когато ти е удобно!)

💥 Изпрати това съобщение на всички българи в Обединеното Кралство които познаваш - това е нашия дом също! 💥
Натисни тук: https://saveourfuture.uk/evropaenasha

🗣 *ТОЗИ ПЪТ МОЖЕШ ДА ГЛАСЪВАШ!!!* 🗣`}
    />
    <Interstitial>
      <p>Изберете пет най-добри приятели или групи, за да изпратите вашето съобщение Whatsapp</p>
      <p>Генериране на съобщение сега</p>
      <p>Можете да персонализирате съобщението в следващата стъпка ...</p>
    </Interstitial>
    <Disclaimer />
  </Layout>
);

WhatsAppEvropaenasha.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppEvropaenasha;

export const query = graphql`
  query EvropaenashaQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
